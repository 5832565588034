class Accordion {
    constructor(divId) {
        if (divId) {
            this.DivContainer = document.getElementById(divId);
        } else {
            this.DivContainer = document;
        }
    }

    /**
    * This method finds all links marked with the 'accordion-link' class and constructs an accordion target string based on the link's text attribute.
    * The string is then added back to the link as an 'accordion-target' data attribute and the link is given a click event listener that handles link clicks.
    */
    activateAccordionLinks() {
        document.querySelectorAll('a.accordion-link').forEach(link => {
            let accordionTarget = link.text.toLowerCase().replace(/\s/g, '');
            link.setAttribute('data-accordion-target', accordionTarget);
            link.onclick = (e) => {
                this.handleClick(e);
            };
        })
    }

    /**
    * This method handles clicks on a.accordion-link elements, by smooth-scrolling to the
    * appropriate accordion, and automatically opening it.
    */

    handleClick(e) {
        e.preventDefault();
        let targetEl = document.getElementById(e.target.getAttribute('data-accordion-target'));
        $(targetEl).siblings().collapse('show')
        //Use JQuery animate to get around a Chrome bug where "behavior: 'smooth'" doesn't work in certain versions of Chrome
        //https://stackoverflow.com/questions/61885401/scrollintoview-is-not-working-in-chrome-version-81-behaviour-smooth-is-not-hap
        $('html, body').animate({
            scrollTop: $(targetEl).offset().top
        }, 1000)
    }

    Init() {
        this.activateAccordionLinks();
    }
}