class FoundationCommerce {
    init() {
        window.cartHelper = new CartHelper();

        // Search & Product List
        var search = new ProductSearch();
        search.Init();
        // --- End

        // Product
        var product = new Product();
        product.Init();

        var productDetail = new ProductDetail('.product-detail');
        productDetail.InitProductDetail();
        // --- End

        // Quick View
        var quickView = new ProductDetail('#quickView');
        quickView.InitQuickView();
        // --- End

        var jsGa4 = new ProductDetail();
        jsGa4.InitJsGa4();

        // Cart
        var cart = new Cart();
        cart.InitLoadCarts();
        cart.InitRemoveItem();
        cart.InitChangeQuantityItem();
        cart.InitChangeVariant();
        cart.InitAddNote();
        cart.InitValidateBeforeCheckout();
        cart.ApplyCouponCode();
        cart.RemoveCouponCode();
        cart.InitJsGa4();
        // --- End

        // My Profile
        var myProfile = new MyProfile();
        var wishList = new Favorites();
 
        // --- End

        // Checkout
        var checkout = new Checkout();
        checkout.InitJsGa4();
        // --- End

        //Order Confirmation
        var orderConfirmation = new OrderConfirmation();
        orderConfirmation.InitJsGaPurchase();
        // --- End

        // Pay invoices
        var payInvoices = new InvoiceCheckout();

        // Order Detail
        var orderDetai = new OrderDetail();
        orderDetai.InitNote();
        orderDetai.InitReturnOrder();
        // --- End


        // Quick Order Block
        $('.jsQuickOrderBlockForm').each(function (i, e) {
            let newBlockId = 'jsQuickOrderBlockForm' + i;
            $(e).attr('id', newBlockId);
            let quickOrderBlock = new QuickOrderBlock('#' + newBlockId);
            quickOrderBlock.Init();
        })
        // --- End

        // AddressBook
        var addressBook = new AddressBook();
        addressBook.Init();
        // --- End

       
        
        // B2B Order
        var b2bOrder = new B2BOrder();
        b2bOrder.Init();
        // --- End

        // Order Search Block
        var orderSearchBlock = new OrderSearchBlock();
        orderSearchBlock.Init();
        // --- End
   }
}