$( document ).ready(function() {
    $('.hero-carousel').each(function () {
        if ($(this).data('showNumber') === "True") {
            $('.carousel-item', this).each(function () {
                let currentIndexPop = $(this).index() + 1;
                let currentIndexPopString = currentIndexPop > 9 ? currentIndexPop : "0" + currentIndexPop;
                $('.jsNumSlide', this).html(currentIndexPopString);
            })
        } else  {
            $('.jsNumSlideContainer', this).hide();
        }
    })

    const BuildGa4Object = (dataset) => {
        return {
            promotion_id: dataset.promotion_id || '',
            promotion_name: dataset.promotion_name || '',
            creative_name: dataset.creative_name || '',
            creative_slot: dataset.creative_slot || '',
            location_id: dataset.location_id || ''
        }
    }
    
    if ($('.jsGa4-promotions-carousel').length) {
        
        let items = []
        $('.jsGa4-promotions-carousel').find('.jsGa4-object').each((i, element) => {
            
            if (element) {
                const dataset = element.dataset
                items.push(BuildGa4Object(dataset))

                // GA4 select promotions
                $(element).click((e) => {
                    const item = BuildGa4Object(e.currentTarget.dataset)

                    if (Object.keys(item).length) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'select_promotion',
                            ecommerce: {
                                items: [item]
                            }
                        });
                    }
                })
            }
        })
    }

    //fire event when carousel is loaded
    sendPromotionData();

    //detect when carousel changes slide
    $('.hero-carousel').on('slid.bs.carousel', function () {
        sendPromotionData();
    });

    function sendPromotionData() {
        setTimeout(function () {
            const promotion = document.getElementsByClassName('carousel-item active')
            var threshold = 0.6; // Visibility threshold (60%)
            if (promotion.length && isElementVisible(promotion[0], threshold)) {
                const item = BuildGa4Object(promotion[0].dataset);

                if (Object.keys(item).length) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'view_promotion',
                        ecommerce: {
                            items: [item]
                        }
                    });
                }
            }
        }, 1000);
    }

    function isElementVisible(el, threshold) {
        var rect = el.getBoundingClientRect();
      
        var elementHeight = el.offsetHeight;
        var visibilityPercentage = Math.max(rect.bottom, 0) / elementHeight;

        return visibilityPercentage >= threshold;
    }
});