/**
 * Searches the block for all password fields containing the class "reveal" and
 * adds hide/reveal functionality to them.
 */

class RevealPassword {
    constructor(divId) {
        if (divId) {
            this.DivContainer = divId;
        } else {
            this.DivContainer = document;
        }
    }

/**
* This method wraps any password field marked with the 'reveal' class in a container along with its validation comments (if any).
* A show/hide icon element is added above the field and is given event listeners that handle interaction with the icon.
*/

    AddRevealFeature() {
        let toggles = Array.from(document.getElementsByClassName('password-toggle'));
        toggles.forEach(function (toggle) {
            toggle.addEventListener('click', function () {
                var input = this.previousElementSibling;
                input.type = input.type === "password" ? "text" : "password";
            });
        });

    }

    Init() {
        this.AddRevealFeature();
    }
}