class Filters {
    constructor() {
        this.init();
    }

    init() {
     
        const filtersBtn = $('.jsFiltersButton');
        const filtersWrapper = $('.jsFiltersBody');  
        const filtersMobileWrapper = $('.jsFiltersMobileBody');
        const selectedFilter = $('.jsSearchFacetSelected');
        
        const activeClass = 'facets__content--active';
        const activeMobileClass = 'category-page__filters-wrapper--active';

        const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
        const scrollToTopValue = deviceWidth > 992 ? 200 : 0;

        filtersBtn.on('click', function (event) {
            event.preventDefault();
            filtersWrapper.toggleClass(activeClass);
            filtersMobileWrapper.toggleClass(activeMobileClass);
        });

        $('body').on('click', function (event) {
            const target = $(event.target);
            if (!target.is(filtersWrapper) && !target.is(filtersBtn) && !filtersWrapper.has(target).length && !target.is(selectedFilter)) {
                filtersWrapper.removeClass(activeClass);
            }
            if (target.is(filtersBtn)) {
                scrollToTop(scrollToTopValue);
            }
        });
    }
}