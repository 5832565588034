class MyCustomers {
    constructor(params) {
        this._container = $(params.containerSelector);
        this._dataUrl = params.dataUrl;
        this._impersonateUrl = params.impersonateUrl;        
        this._language = params.language;
        this._columns = params.columns;
        this._pageSize = params.pageSize || 20;
		this._paging = typeof(params.paging) !== "undefined" ? params.paging : true;
        this._sorting = typeof params.sorting !== "undefined" ? params.sorting : true;
        this._caching = !!params.caching;
        this._defaultSorting = params.defaultSorting || [0, "asc"];
        this._onRowClick = params.onRowClick;
    }

    init() {
        const inst = this;       

        this._searchBox = this._container.find("input[name=textFilter]");

        this._dataTable = this._container.find(".dataTable").DataTable({
            responsive: {
                details: {
                    type: "column",
                    target: -1
                }
            },
            columnDefs: [{
                className: "control",
                orderable: false,
                targets: -1
            },{
                responsivePriority: 1,
                targets: [0, 1, -2, -1]
            },{
                responsivePriority: 5,
                targets: [2, 3]
            }],
            processing: true,
            serverSide: true,
            searching: false,
            lengthChange: false,
            info: false,
            paging: this._paging,
            ordering: this._sorting,
            pagingType: "full_numbers",
            lengthMenu: [this._pageSize],
            ajax: {
                method: "GET",
                cache: this._caching,
                url: this._dataUrl,
                data: function(data) {
                    data.text = inst._searchBox.val();               

                    const sorting = data.order && data.order.length
                        ? data.order[0]
                        : { column: inst._defaultSorting[0], dir: inst._defaultSorting[1] };

                    const sortColumn = data.columns[sorting.column];
                    data.sortOrder = (sortColumn.name || sortColumn.data) + "|" + sorting.dir;
                    data.length = data.length > 0 ? data.length : inst._pageSize;

                    delete data.search;
                    delete data.columns;
                    delete data.order;
                }
            },

            columns: this._columns,
            order: [this._defaultSorting],

            language: {
                paginate: {
                    first: "<i class=\"fal fa-angle-double-left\"></i>",
                    previous: "<i class=\"fal fa-angle-left\"></i>",
                    next: "<i class=\"fal fa-angle-right\"></i>",
                    last: "<i class=\"fal fa-angle-double-right\"></i>"
                },
                processing: $('body > .loading-box').show(),
                loadingRecords: $('body > .loading-box').show(),
                emptyTable: this._language.noRecords
            },
            dom: "rt<\"hide\"i><\"dataTables__bottom\"p>",
            initComplete: (oSettings) => {
                if (oSettings.json.recordsFiltered < inst._pageSize + 1) {
                    this.hidePaginationForFirstPage();
                } else {
                    this.showPaginationForFirstPage();
                }
            },
            drawCallback: (oSettings) => {
                if (oSettings.json.recordsFiltered < inst._pageSize + 1) {
                    this.hidePaginationForFirstPage();
                } else {
                    this.showPaginationForFirstPage();
                }
            },
        });

        this._container.on("click", ".jsImpersonateButton", inst._impersonateClick.bind(this));

        $('.claims-invoices-search--input').on('keypress', function () {
            inst._dataTable.draw();
        });

        this._container.on("click", '.jsSearchButton', function (e) {
            e.preventDefault();
            inst._dataTable.ajax.reload();
        });

        this._container.on("keyup", function (e) {
            if (e.keyCode === 13) {
                inst._dataTable.ajax.reload();
            }
        });

        this._container.find('.jsSearchTrigger').on('change', function (e) {
            const value = e.currentTarget.value;
            const name = e.currentTarget.name;         
            inst._dataTable.ajax.reload();
        });
      
        $.fn.DataTable.ext.pager.numbers_length = 5;  
      
    }

    hidePaginationForFirstPage() {
        let pagination = $(".order-claim-history__body .dataTables__bottom");
        pagination.addClass("hidden");
    }

    showPaginationForFirstPage() {
        let pagination = $(".order-claim-history__body .dataTables__bottom");
        pagination.removeClass("hidden");
    }

    _impersonateClick(e) {
        e.preventDefault();
        const customerNumber = $(e.currentTarget).data("customernumber");

        if (!customerNumber) {
            notification.Error('No CustomerNumber found.');
            return;
        }
        axios
            .post(this._impersonateUrl, { customerNumber: customerNumber})
            .then(function (result) {
                if (result.data.success) {
                    window.location.href = result.data.redirectUrl;
                }
                else {
                    notification.Error(result.data.error);
                }
            })
            .catch(function(error) {
                notification.Error(error);
            });
    }  
}
window.MyCustomers = MyCustomers;