class Cart {

    changeInfoCart(result) {
        const inst = this;
        let subtotal = inst.roundMoney(result.data.SubTotal);
        let promotionalDiscount = inst.roundMoney(result.data.PromotionalDiscount);
        let savings = inst.roundMoney(result.data.Savings);
        let total = inst.roundMoney(result.data.Total);
        let customization = result.data.HandlingTotal;
        let shippingTotal = result.data.ShippingTotal; 
        let couponCodeCount = result.data.CouponCodes && result.data.CouponCodes.length;
        $('.largecart-Subtotal').html(`$${subtotal} USD`);
        $('.largecart-PromotionalDiscount').html(`- $${promotionalDiscount} USD`);
        $('.largecart-Savings').html(`- $${savings} USD`);
        $('.largecart-Total').html(`$${total} USD`);
        $('.largecart-Customization').html(`$${customization} USD`);
        $('.largecart-shippingTotal').html(`$${shippingTotal} USD`);
        if (couponCodeCount == 0) {
            $('.promotion__coupon').addClass("hidden");
        }
        else {
            $('.promotion__coupon').removeClass("hidden");
        }
        cartHelper.SetCartReload(result.data.CountItems);
    }

    removeItem(url, elementClick, typeCart) {
        var inst = this;
        var data = {
            Code: elementClick.attr('code'),
            ShipmentId: elementClick.attr('shipmentId'),
            RequestFrom: elementClick.attr('type')
        };

        axios.post(url, data)
            .then(function (result) {
                if (result.data.StatusCode == 0) {
                    notification.Error(result.data.Message);
                }
                else if (result.data.StatusCode == 1) {
                    if (typeCart == 'cart') {
                        $('.countItemCartHeader').each(function (i, el) {
                            $(el).html(result.data.CountItems);                           
                        });
                        $('.amountCartHeader').each(function (i, el) {
                            $(el).html("$" + result.data.SubTotal.Amount);                             
                        });
                    }
                    if (result.data.GygiValidationMessages === null) {
                        let messageElements = result.data.GygiValidationMessages;
                        $('.jsCheckoutError').html(messageElements).hide();
                    }
                    if (typeCart !== 'large-cart') {
                        elementClick.parents('.cart__row').first().remove();
                        if (typeCart == "cart") cartHelper.SetCartReload(result.data.CountItems);
                        else cartHelper.SetWishlistReload(result.data.CountItems);

                    } else {
                        // GA4 Remove from cart
                        const jsCartItem = elementClick.closest('.jsCartItem')
                        if (jsCartItem.length) {
                            const item = inst.BuildGa4Object(jsCartItem[0].dataset)

                            if (item) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: 'remove_from_cart',
                                    ecommerce: {
                                        items: [item]
                                    }
                                });
                            }
                        }
                        
                        if (result.data.CountItems === 0) {
                            window.location.reload();
                        } else {
                            elementClick.parents('.jsCartItem').first().remove();
                            inst.changeInfoCart(result);
                        }
                    }
                }
                else {
                    notification.Error(result.data.Message);
                }
                inst.InitJsGa4();
            })
            .catch(function (error) {
                notification.Error(error);
            })
            .finally(function () {

            });
    }

    changeVariant(url, data, elementChange) {
        var inst = this;
        axios.post(url, data)
            .then(function (result) {
                var container = $(elementChange).parents('.product-tile-list__item').first();
                $(container).html(result.data);
                inst.InitCartItems(container);
                feather.replace();
                var dropdown = new Dropdown(container);
                dropdown.Init();
                notification.Success("Success");
                inst.InitJsGa4();
            })
            .catch(function (error) {
                notification.Error(error);
            })
            .finally(function () {

            });
    }

    changeQuantity(element) {
        const inst = this;
        let code = $(element).attr('code');
        let shipmentId = $(element).attr('shipmentId');
        let qty = $(element).val();
        let url = $(element).attr('url');
        let data = {
            Code: code,
            ShipmentId: shipmentId,
            Quantity: qty
        };
        $(element).attr('disabled', 'disabled');
        axios.post(url, data)
            .then(function (result) {
                switch (result.data.StatusCode) {
                    case 0:
                        $(element).siblings('.required').html(result.data.Message);
                        notification.Warning(result.data.Message);
                        break;
                    case -1:
                        notification.Error(result.data.Message);
                        break;
                    default:
                        let currencySign = $(element).attr('currency');
                        let itemTotal = inst.roundMoney(result.data.ItemTotal);
                        let itemSavings = inst.roundMoney(result.data.ItemSavings);
                        let originalPrice = $(".price__original");
                        $('.total-' + code).html(currencySign + itemTotal);
                        $('.savings-' + code).html(currencySign + itemSavings);
                        $(element).parents('.product-tile-list__item').first().find('.currentVariantInfo').attr('quantity', qty);
                        if (originalPrice) {
                            let discount = inst.roundMoney(result.data.DiscountedUnitPrice);
                            let placedPrice = inst.roundMoney(result.data.PlacedPrice);
                            if (discount < placedPrice) {
                                $('.discount-' + code).html(currencySign + discount + " USD your price");
                                $('.price-' + code).addClass("price__old");
                            }
                            else {
                                $('.discount-' + code).html("");
                                $('.price-' + code).removeClass("price__old");
                            }
                        }
                        inst.changeInfoCart(result);
                        break;
                }
                $('.jsCartItem[data-item_id="' + code + '"]').attr('data-item_quantity', qty);
                inst.InitJsGa4();
            })
            .catch(function (error) {
                notification.Error(error);
            })
            .finally(function () {
                $(element).removeAttr('disabled');
            });
    }

    addNote(element) {
        let form = $(element.target).parents('.jsAddNoteContainer').first();
        let url = $(form).data('url');

        let bodyFormData = new FormData();
        bodyFormData.set('note', $(element.target).val());
        bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]").val());

        axios.post(url, bodyFormData)
            .then(function (r) {
                if (r.status !== 200) {
                    console.log(r);
                }
            })
            .catch(function (e) {
                notification.Error(e);
            });
    }

    validateBeforeCheckout(element) {
        const checkoutUrlContainer = $('#checkoutUrls');
        const checkoutUrl = checkoutUrlContainer && $('#checkoutUrls').data('checkout-url');
        const url = checkoutUrlContainer && $('#checkoutUrls').data('url');

        $('.jsCheckoutError').hide();
        $(element).attr('disabled', 'disabled');

        axios.post(url)
            .then(function (result) {
                switch (result.data.StatusCode) {
                    case 0:
                        $('.jsCheckoutError').html(messageElements).hide();
                        window.location.href = checkoutUrl;                       
                        break;
                    case -1:
                        let messageElements = result.data.GygiValidationMessages.map((m) => `<p>${m}</p>`);
                        $('.jsCheckoutError').html(messageElements).show();
                        break;
                }
            })
            .catch(function (error) {
                notification.Error(error);
            })
            .finally(function () {
                $(element).removeAttr('disabled');
            });
    }

    // Coupon code handle 
    ApplyCouponCode() {
        var inst = this;
        $('.jsCouponCode').keypress(function (e) {
            if (e.keyCode == 13) {
                $('.jsAddCoupon').click();
                return false;
            }
        });

        $('.jsAddCoupon').click(function () {
            var form = $(this).parents('.jsAddCouponContainer').first();
            var url = form.attr('action');
            var couponCode = form.find('.jsCouponCode').val();
            var data = convertFormData({ couponCode: couponCode });
            axios.post(url, data)
                .then(function (r) {
                    if (r.status == 200) {
                        $('.jsCouponLabel').removeClass('hidden');
                        $('.jsCouponListing').append(inst.couponTemplate(couponCode));

                        inst.RemoveCouponCode($('.jsRemoveCoupon[data-couponcode=' + couponCode + ']'));
                        $('.jsCouponReplaceHtml').html(r.data);
                        feather.replace();
                        form.find('.jsCouponCode').val("");
                        $('.jsCouponErrorMessage').hide();
                    } else {
                        $('.jsCouponErrorMessage').show();
                    }
                })
                .catch(function (e) {
                    notification.Error(e);
                });
        });
    }

    RemoveCouponCode(selector) {
        var inst = this;
        if (selector) {
            inst.removeCoupon(selector);
        } else {
            $('.jsRemoveCoupon').each(function (i, e) {
                inst.removeCoupon(e);
            });
        }
    }

    removeCoupon(e) {
        $(e).click(function () {
            var element = $(this);
            var url = $('#jsRemoveCouponUrl').val();
            var couponCode = $(this).data('couponcode');
            var data = convertFormData({ couponCode: couponCode });
            axios.post(url, data)
                .then(function (r) {
                    element.remove();
                    var coupons = $('.jsCouponListing').find('.jsRemoveCoupon');
                    if (coupons.length == 0) {
                        $('.jsCouponLabel').addClass('hidden');
                    }
                    $('.jsCouponReplaceHtml').html(r.data);
                    $('.jsCouponErrorMess').hide();
                })
                .catch(function (e) {
                    notification.Error(e);
                });
        });
    }

    couponTemplate(couponCode) {
        return `<label class="filters-tag jsRemoveCoupon" data-couponcode="${couponCode}">
                    <span>${couponCode}</span>
                    <span class="filters-tag__remove"><i class="cursor-pointer" data-feather="x" width="12"></i></span>
                </label>`;
    }

    setTextAreaCounter(e) {
        const maxLength = 255;

        let textLength = 0;
        if (e.target === undefined) {
            textLength = $(e).val().length;
        } else {
            textLength = $(e.target).val().length;
        }

        let textRemaining = maxLength - textLength;
        $('#count').html(textRemaining);
    }

    LoadMiniCartClick(urlLoadCart, clickSelector, reloadSelector) {
        var inst = this;
        $(clickSelector).click(function () {
            var isNeedReload = $(this).attr('reload');
            if (isNeedReload == 1) { // reload mini cart
                $(reloadSelector + " .loading-cart").show();
                axios.get(urlLoadCart, null)
                    .then(function (result) {
                        $(reloadSelector + " .cart-item-listing").html(result.data);
                        inst.InitRemoveItem(reloadSelector);
                        $(clickSelector).attr('reload', 0);
                    })
                    .catch(function (error) {
                        notification.error(error);
                    })
                    .finally(function () {
                        $(reloadSelector + " .loading-cart").hide();
                    });
            }
        });
    }

    InitLoadCarts() {
        var inst = this;
        $('.jsCartBtn').each(function (i, e) {
            var url = $(e).data("reloadurl");
            var container = $(e).data("cartcontainer");
            inst.LoadMiniCartClick(url, e, container);
        });
        $('.jsWishlistBtn').each(function (i, e) {
            var url = $(e).data("reloadurl");
            var container = $(e).data("cartcontainer");
            inst.LoadMiniCartClick(url, e, container);
        });
    }


    InitChangeVariant(selector) {
        var inst = this;
        if (selector == undefined) {
            selector = document;
        }

        $(selector).find('.jsChangeSizeVariantLargeCart').each(function (i, e) {
            $(e).change(function () {
                var parent = $(e).parents('.product-tile-list__item').first();
                var variantInfo = $(parent).find('.currentVariantInfo').first();
                var data = {
                    Code: variantInfo.val(),
                    Size: variantInfo.attr('size'),
                    Quantity: variantInfo.attr('quantity'),
                    NewSize: $(e).val(),
                    ShipmentId: variantInfo.attr('shipmentId'),
                    RequestFrom: "changeSizeItem"
                };
                var url = variantInfo.attr('url');

                inst.changeVariant(url, data, e);
            });
        });
    }

    InitAddNote(selector) {
        const inst = this;
        if (selector == undefined) {
            selector = document;
        }

        if ($('#textarea').length !== 0) {
            inst.setTextAreaCounter('#textarea');
            $(selector).on('keyup', '#textarea', inst.setTextAreaCounter);
            $(selector).on('focusout', '.jsNoteText', inst.addNote)
        }
    }

    InitValidateBeforeCheckout(selector) {
        var inst = this;
        if (selector == undefined) {
            selector = document;
        }

        $(selector).on("click", ".jsValidateBeforeCheckout", function (e) {
            e.preventDefault();
            inst.validateBeforeCheckout();
        });
    }

    InitChangeQuantityItem(selector) {
        var inst = this;
        if (selector == undefined) {
            selector = document;
        }
        $(selector).find('.jsChangeQuantityCartItem').each(function (i, e) {
            $(e).change(function () {
                var valueInt = parseInt($(e).val());
                var step = parseInt($(e).attr('step'));

                // time out function is rqeuired to wait for dome
                // to update quantity to minimum if it's not already.

                if (!isNaN(valueInt) && Number.isInteger(valueInt)) {
                    $(e).siblings('.required').html("");
                    if (valueInt > 0 && valueInt % step === 0) {
                        setTimeout(function () { inst.changeQuantity($(e)) }, 700);
                    }
                    else {
                        let higherBound = Math.ceil(valueInt / step) * step;
                        let lowerBound = Math.floor(valueInt / step) * step;
                        valueInt = Math.abs(valueInt - higherBound) < Math.abs(valueInt - lowerBound) ? higherBound : lowerBound;
                        let changedValue = Math.abs(valueInt);
                        $(e).val(changedValue);
                        setTimeout(function () { inst.changeQuantity($(e)) }, 700);
                    }
                }
                else {
                    $(e).siblings('.required').html("This field must be a number.");
                }
            });
        });
    }


    InitRemoveItem(selector) {
        const inst = this;
        if (selector == undefined) {
            selector = document;
        }

        $(selector).find('.jsRemoveCartItem').each(function (i, e) {
            $(e).click(function () {
                let type = $(this).attr('type');
                let url = "/defaultcart/RemoveCartItem";

                if (type === "wishlist") {
                    url = "/wishlist/RemoveWishlistItem";
                }

                if (type === "large-cart") {
                    url = "/defaultcart/RemoveCartItem";
                    
                    
                    
                }

                inst.removeItem(url, $(this), type);
            });
        });
    }

    roundMoney(money) {
        if (money === undefined) {
            return 0.00;
        }
        let digits = (money.Currency !== undefined) ? money.Currency.Format.CurrencyDecimalDigits : 2;

        return money.Amount.toLocaleString(undefined, { 'minimumFractionDigits': digits, 'maximumFractionDigits': digits });
    }

    BuildGa4Object(dataset) {
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: dataset.price || '',
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            quantity: dataset.item_quantity || ''
        }
    }
    
    InitJsGa4() {
        const inst = this;
        const items = []
        document.querySelectorAll('.jsCartItem').forEach((element) => {
            if (element) {
                // GA4 Cart List
                items.push(inst.BuildGa4Object(element.dataset))
            }
        });

        if (items.length) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'view_cart',
                ecommerce: {
                    items
                }
            });
        }
    }

    InitCartItems(selector) {
        const inst = this;
        inst.InitRemoveItem(selector);
        inst.InitChangeQuantityItem(selector);
        inst.InitChangeVariant(selector);
    }
}


class CartHelper {
    SetCartReload(totalItem) {
        if (totalItem != undefined) {
            $('.cartCount').each(function (i, e) {
                $(e).html(': ' + totalItem);
                //$(e).attr('reload', 1);
            });
        }
    }

    SetWishlistReload(totalItem) {
        if (totalItem != undefined) {
            $('.jsWishlistBtn').each(function (i, e) {
                $(e).find('.icon-menu__badge').first().html(totalItem);
                $(e).attr('reload', 1);
            });
        }
    }

    AddWishlist() {
        var wishlistHeader = $('#js-wishlist').children('.icon-menu__badge').first();

        var newQty = parseInt(wishlistHeader.html()) + 1;
        this.SetWishlistReload(newQty);
    }

    SubtractWishlist() {
        var wishlistHeader = $('#js-wishlist').children('.icon-menu__badge').first();

        var newQty = parseInt(wishlistHeader.html()) - 1;
        this.SetWishlistReload(newQty);
    }

    SubtractWishlist() {
        var wishlistHeader = $('#js-wishlist').children('.icon-menu__badge').first();

        var newQty = parseInt(wishlistHeader.html()) + 1;
        this.SetWishlistReload(newQty);
    }
}