class InvoicePayments {

    constructor(params) {
        this.DESKTOP_BREAKPOINT = 992;
        this._container = $(params.containerSelector);
        this._dataUrl = params.dataUrl;
        this._paging = typeof params.paging !== "undefined" ? params.paging : true;
        this._sorting = typeof params.sorting !== "undefined" ? params.sorting : true;
        this._caching = !!params.caching;
        this._pageSize = params.pageSize || 20;
        this._columns = params.columns;
        this._defaultSorting = params.defaultSorting;
        this._language = params.language;
        this._serverSide = typeof params.serverSide !== "undefined" ? params.serverSide : true;
        this._onRowClick = params.onRowClick;
        this._formatRow = params.formatRow;
    }

    init() {
        const inst = this;
        $.fn.dataTable.ext.errMode = 'none';
        $.fn.DataTable.ext.pager.numbers_length = 5;

        this._text = this._container.find("input[name=textFilter]");
        this._fromDate = this._container.find("#fromDate");
        this._toDate = this._container.find("#toDate");
        this._paymentsList = this._container.find('.jsPaymentsList');
        this._totalPayment = this._container.find('.payments__total-value');

        this._paymentFields = [];

        this.initDatePicker();

        this._dataTable = this._container.find(".dataTable").DataTable({
            responsive: {
                details: {
                    type: 'column',
                    target: -1,
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                `<dt data-dt-row="${rowIdx}">` + col.title + '</dt> ' +
                                `<dd data-dt-row="${rowIdx}">` + col.data + '</dd>' : '';
                        }).join('');

                        return data ? $('<dl class="dtr-details payments__amount-details" />').append(data) : false;
                    }
                }
            },
            columnDefs: [
                {
                    responsivePriority: 1, targets: [0, 1, -3, -1]
                }, {
                    className: 'dtr-control',
                    orderable: false,
                    targets: -1
                }],
            processing: true,
            serverSide: this._serverSide,
            searching: false,
            lengthChange: false,
            info: false,
            paging: this._paging,
            ordering: this._sorting,
            pagingType: "full_numbers",
            lengthMenu: [this._pageSize],

            "fnDrawCallback": function (oSettings) {
                if (!oSettings.json) return;
                const $paginationContainer = $(this).siblings('.dataTables__bottom');
                const $summaryContainer = $('.payments__total');


                // hide pagination for single page
                $paginationContainer.toggle(oSettings.json.recordsTotal > inst._pageSize);
                $summaryContainer.toggle(oSettings.json.recordsTotal > 0);
                $paginationContainer.find('.paginate_button').on('click', function () {
                    $(window).scrollTop(0);
                });
            },

            "initComplete": function () {
                inst.bindInputs();
                inst.bindHelpers();
                inst.populateSavedData();
            },

            ajax: {
                method: "GET",
                url: this._dataUrl,
                data: function (data) {
                    data.InvoiceNumber = inst._text.val();
                    data.FromDate = inst._fromDate.val();
                    data.ToDate = inst._toDate.val();

                    const sorting = data.order && data.order.length
                        ? data.order[0]
                        : {column: inst._defaultSorting[0], dir: inst._defaultSorting[1]};

                    if (inst._serverSide) {
                        const sortColumn = data.columns[sorting.column];
                        data.sortOrder = (sortColumn.name || sortColumn.data) + "|" + sorting.dir;
                        data.length = data.length > 0 ? data.length : inst._pageSize;
                    }

                    delete data.search;
                    delete data.columns;
                    delete data.order;
                }
            },

            columns: this._columns,
            order: [this._defaultSorting],

            language: {
                paginate: {
                    first: "<i class=\"fal fa-angle-double-left\"></i>",
                    previous: "<i class=\"fal fa-angle-left\"></i>",
                    next: "<i class=\"fal fa-angle-right\"></i>",
                    last: "<i class=\"fal fa-angle-double-right\"></i>"
                },
                processing: $('body > .loading-box').show(),
                loadingRecords: $('body > .loading-box').show(),
                emptyTable: this._language.noRecords
            },

            dom: "rt<\"hide\"i><\"dataTables__bottom\"p>"
        });

        this._totalPayment.inputmask({alias: "numeric", groupSeparator: ',', digits: 2, digitsOptional: false, prefix: '$', suffix: ' USD'});

        this._dataTable.on('draw.dt', function () {
            inst.bindHelpers();
            inst.selectSavedFields();
        }).on('responsive-display', function () {
            inst.bindHelpers();
        });

        $('.claims-invoices-search--input').on('keypress', function () {
            inst._dataTable.draw();
        });

        this._container.on("click", '.jsSearchButton', function (e) {
            e.preventDefault();
            inst._dataTable.ajax.reload();
        });

        this._container.on('keydown', 'form', function (e) {
            if (e.keyCode === 13) {
                return false;
            }
        });

        // Select filter "All" default for desktop
        if ($(window).width() > this.DESKTOP_BREAKPOINT) {
            this._container.find('.jsSearchTrigger').first().prop("checked", true);
        }

        this._container.find('.jsSearchTrigger').on('change', (e) => {
            const value = e.currentTarget.value;
            inst._hidden.val(value);
            inst._dataTable.ajax.reload();
            // remove mobile placeholder on select
            this._container.find('.jsFilterMobilePlaceholder').remove();
            this._container.find('.dropdown__selected').removeClass('d-none d-lg-block');
        });


        if (typeof this._onRowClick === "function") {
            this._container.find(".dataTable").on("click", "tbody tr", function (e) {
                const row = inst._dataTable.row(this);
                const data = row.data();

                inst._onRowClick(e, row, data);
            });
        }
    }

    populateSavedData() {

        if (this._paymentFields.length == 0 && sessionStorage.getItem('_paymentFields')) {
            const savedData = JSON.parse(sessionStorage.getItem('_paymentFields'));

            if (savedData.length > 0) {
                for (let i in savedData) {

                    if (this._paymentFields.indexOf(savedData[i]) < 0) {

                        const data = savedData[i].data;

                        const newField = {
                            data: savedData[i].data,
                            inputItem: $(`<input class="jsSelectedPayments" type="hidden" name="Payments.Index" value="${data.number}" />`),
                            inputNumber: $(`<input class="jsSelectedPayments" type="hidden" name="Payments[${data.number}].InvoiceNumber" value="${data.number}"/>`),
                            inputAmount: $(`<input class="jsSelectedPayments" type="hidden" name="Payments[${data.number}].Amount" value="${data.amount}"/>`),
                        };

                        this._paymentFields.push(newField);
                    }
                }

                this.selectSavedFields();
            }
        }
    }

    setInputMask() {
        let inputs = this._container.find(".payments__amount-control");

        inputs.each(function (index, item) {
            var input = $(item);

            input.inputmask({alias: "numeric", groupSeparator: ',', digits: 2, digitsOptional: false, prefix: '$', suffix: ' USD'});
            input.off('blur').on('blur', function () {
                let amount = parseFloat(input.inputmask('unmaskedvalue'));
                if (amount > parseFloat(input.attr('max'))) {
                    input.inputmask('setvalue', input.attr('max'));
                }
                input.change();
            });          
        });
    }

    bindHelpers() {

        this.bindAutoSelect();
        this.bindSelectPayments();
        this._container.find('[data-toggle="tooltip"]').tooltip();
        this.setInputMask();

    }

    bindInputs() {
        let inst = this;

        this._paymentsList.on('keyup', '.payments__amount-control', function (e) {
            if (e.keyCode === 13) {
                $(this).blur();
            }
        });

        this._paymentsList.on('change', '.payments__amount-row .payments__amount-control', function () {
            let input = $(this);
            let cell = inst._dataTable.cell(input.parents('td').get(0));
            let amount = parseFloat(input.inputmask('unmaskedvalue'));
            let parentRowCheckbox = input.parents('tr').find('input[name="selectRow"]');

            if (amount < parseFloat(input.attr('min'))) {
                amount = parseFloat(input.attr('min'));
                input.val(amount);
                input.inputmask('setvalue', amount);
            }

            input.parents('tr').removeClass('payments__amount--autofill');

            cell.data(amount).render('display');

            inst.setInputMask();
            // update changes
            parentRowCheckbox.prop('checked', true).change();

        });

        // details input behavior
        this._paymentsList.on('change', '.payments__amount-details .payments__amount-control', function () {
            let input = $(this);
            let name = input.attr('name');
            let parentInput = inst._container.find(`[name="${name}"]`).eq(0);

            let cell = inst._dataTable.cell(parentInput.parents('td').get(0));

            let amount = parseFloat(input.inputmask('unmaskedvalue'));

            if (amount < parseFloat(input.attr('min'))) {
                amount = parseFloat(input.attr('min'));
                input.inputmask('setvalue', amount);
            }

            cell.data(amount).render('display');

            inst.setInputMask();

            parentInput = inst._container.find(`[name="${name}"]`).eq(0);

            // update changes
            parentInput.closest('tr')
                .removeClass('payments__amount--autofill')
                .find('input[name="selectRow"]').prop('checked', true).change();
        });
    }

    initDatePicker() {
        let inst = this;
        this._container.find('.jsPaymentsDateRange').daterangepicker({
            opens: 'left'
        }, function (start, end, label) {
            $('#fromDate').val(start.format('MM/DD/YYYY'));
            $('#toDate').val(end.format('MM/DD/YYYY'));

            inst._dataTable.ajax.reload();
        });
    }

    getFieldIndexByNumber(number) {
        for (let i in this._paymentFields) {
            if (this._paymentFields[i].data.number == number) {
                return i;
            }
        }

        const newField = {
            data: {
                number: '',
                amount: ''
            },
            inputItem: $(`<input class="jsSelectedPayments" type="hidden" name="Payments.Index" value="" />`),
            inputNumber: $(`<input class="jsSelectedPayments" type="hidden"/>`),
            inputAmount: $(`<input class="jsSelectedPayments" type="hidden"/>`),
        };

        newField.data = {
            number: number,
            amount: 0
        };

        newField.inputItem.val(number);
        newField.inputNumber.val(number).attr('name', `Payments[${number}].InvoiceNumber`);
        newField.inputAmount.val(0).attr('name', `Payments[${number}].Amount`);

        const newFieldIndex = this._paymentFields.push(newField) - 1;

        return newFieldIndex;
    }

    updatePayments(number, amount) {
        let fieldIndex = this.getFieldIndexByNumber(number);

        if (fieldIndex) {
            if (amount > 0) {
                this._paymentFields[fieldIndex].inputAmount.val(amount);
                this._paymentFields[fieldIndex].data.amount = amount;
            } else {
                this._paymentFields.splice(fieldIndex, 1);
            }
        }

        sessionStorage.setItem('_paymentFields', JSON.stringify(this._paymentFields));

        this.calculateTotal();
    }

    calculateTotal() {
        let totalValue = this._paymentFields.reduce(function (total, current) {
            return total + parseFloat(current.inputAmount.val());
        }, 0);

        this._totalPayment.inputmask('setvalue', totalValue);
    }

    selectSavedFields() {
        const inst = this;
        this._paymentFields.map(function (item) {
            const input = inst._paymentsList.find(`input[name="${item.data.number}_Amount"]`);

            if (input.length) {
                input.val(item.data.amount);
                input.inputmask('setvalue', parseFloat(item.data.amount));
                input.change();

                setTimeout(function () {
                    inst._paymentsList.find(`input[name="${item.data.number}_Amount"]`).closest('tr').addClass('payments__amount--autofill');
                }, 500);
            }
        });
    }

    bindAutoSelect() {
        let selectAllCheckbox = this._paymentsList.find('[name="selectAllRows"]');
        let rows = this._paymentsList.find('tbody tr:not(.child)');

        selectAllCheckbox.on('change', function () {

            rows.each(function (index, item) {
                let checkbox = $(item).find('[name="selectRow"]');

                if (selectAllCheckbox.prop('checked')) {
                    if (!checkbox.prop('checked')) {
                        checkbox.prop('checked', true).change();
                    }
                } else {
                    if (checkbox.prop('checked')) {
                        checkbox.prop('checked', false).change();
                    }
                }
            });
        }
        );
    }

    bindSelectPayments() {
        let inst = this;

        this._paymentsList.find('tr').each(function () {

            let row = $(this);
            let selectCheckbox = $(this).find('input[name="selectRow"]');

            selectCheckbox.on('change', function () {

                let cell = inst._dataTable.cell(row.find('.payments__amount-row').get(0));

                // if row is visible
                if (typeof cell.data() !== 'undefined') {
                        
                    let number = $(this).val();

                    let input = row.find('.payments__amount-row .payments__amount-control');
                    let detailsInput = row.next('.child').find('.payments__amount-details .payments__amount-control');

                    let amount = parseFloat(input.inputmask('unmaskedvalue'));

                    if (amount == 0) {
                        setTimeout(function () {
                            row.addClass('payments__amount--autofill')
                        }, 300);
                        amount = parseFloat(input.attr('max'));
                    }         

                    let paymentFieldIndex = inst.getFieldIndexByNumber(number);

                    let paymentField = inst._paymentFields[paymentFieldIndex];

                    paymentField.inputItem.val(number);
                    paymentField.inputNumber.val(number).attr('name', `Payments[${number}].InvoiceNumber`);
                    paymentField.inputAmount.val(amount).attr('name', `Payments[${number}].Amount`);


                    if ($(this).prop('checked')) {
                        row.addClass('selected');

                        paymentField.data = {
                            number: number,
                            amount: amount
                        };

                        inst._paymentsList.after(paymentField.inputItem);
                        inst._paymentsList.after(paymentField.inputNumber);
                        inst._paymentsList.after(paymentField.inputAmount);

                        if (detailsInput.length > 0) {
                            detailsInput.inputmask('setvalue', amount);
                        }

                    } else {
                        amount = 0;
                        row.removeClass('selected');
                        row.removeClass('payments__amount--autofill');
                        paymentField.inputItem.remove();
                        paymentField.inputNumber.remove();
                        paymentField.inputAmount.remove();

                        input.inputmask('setvalue', 0);

                        if (detailsInput.length > 0) {
                            detailsInput.inputmask('setvalue', 0);
                        }
                    }

                    inst.updatePayments(number, amount);
                    cell.data(amount).render('display');
                    inst.setInputMask();

                }
            });
        });
    }

}
window.InvoicePayments = InvoicePayments;

class InvoiceCheckout {
    constructor() {
        this.checkoutForm = $('.jsInvoicePayments');
        this.paymentOptions = $('.jsPaymentOption');
        this.submitButton = $('.jsPayInovicesSubmit');
        this.paymentMethodErrorMessage = $('.jsSelectPayMethod');
        if (this.checkoutForm.length) {
            this.initHandlers();
        }
        this.initErrors();
    }

    initHandlers() {
        const inst = this;

        this.checkoutForm
            .on("click", ".jsPaymentOption", (e) => {
                this.toggleOption($(e.target));
            })
            .on("change", '#SaveNewCardInWallet', (e) => {
                this.toggleSaveNewCard($(e.target));
            })
            .on('click', '.jsPayInovicesSubmit', (e) => {
                e.preventDefault();
                this.submitValidate();
            });

        this.checkoutForm.validate({
            invalidHandler: function (event, validator) {
                event.preventDefault();
                var errors = validator.numberOfInvalids();
                if (errors) {
                    inst.paymentMethodErrorMessage.removeClass('d-none');
                } else {
                    inst.paymentMethodErrorMessage.addClass('d-none');
                }
            },
            errorPlacement: function (error, element) {
                return false;
            }
        });
    }

    initErrors() {
        const errors = $('.checkout__error').css('display');
        if (errors == "block") {
            const paymentErrors = document.getElementById("checkoutError");
            paymentErrors.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            let submitButton = $('.jsPayInovicesSubmit');
            submitButton.text(submitButton.data('lblHosted'));
        }
    }

    submitValidate(target) {
        if (!this.checkoutForm.valid()) {
            return;
        }
        const formObj = this.checkoutForm[0];
        const radioBtn = $("input[name='PaymentMethod'][type='radio']:checked");
        if (radioBtn.val() === 'NewCard') {
            $.ajax({
                url: '/Elavon/GetInvoiceToken',
                dataType: "json",
                type: "POST",
                contentType: 'application/json',
                data: {},
                success: function (data) {
                    document.getElementById('elavontoken').value = data;
                    openLightbox(() => { formObj.submit(); });
                },
                error: function () {
                    alert('error');
                }
            });
        }
        else
        {
            formObj.submit();
        }
    }

    toggleOption(target) {

        const panel = target.closest(".jsPaymentOption");

        this.paymentOptions.removeClass("payment-option--selected");
        panel.addClass("payment-option--selected");

        let radioBtn = panel.find("input[type='radio']");
        radioBtn.prop("checked", true);

        this.paymentMethodErrorMessage.addClass('d-none');

        if (radioBtn.val() !== 'NewCard') {
            this.submitButton.text(this.submitButton.data('lblDefault'));
        } else {
            this.submitButton.text(this.submitButton.data('lblHosted'));
        }

    }

    toggleSaveNewCard(target) {
        let checkbox = target.closest("#SaveNewCardInWallet");
        let value = checkbox && checkbox.val() === 'true' ? 'false' : 'true';
        checkbox && checkbox.val(value);
    }
}