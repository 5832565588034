class OrderHistory {
    constructor(params) {
        this.DESKTOP_BREAKPOINT = 992;
        this._container = $(params.containerSelector);
        this._dataUrl = params.dataUrl;
        this._paging = typeof (params.paging) !== "undefined" ? params.paging : true;
        this._sorting = typeof params.sorting !== "undefined" ? params.sorting : true;        
        this._caching = !!params.caching;
        this._pageSize = params.pageSize || 20;
        this._columns = params.columns;
        this._defaultSorting = params.defaultSorting;
        this._language = params.language;
        this._serverSide = typeof params.serverSide !== "undefined" ? params.serverSide : true;
        this._onRowClick = params.onRowClick;
        this._formatRow = params.formatRow;
    }

    init() {
        this._text = this._container.find("input[name=textFilter]");

        const inst = this;

        const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
        const scrollToTopValue = deviceWidth > 992 ? 200 : 0;


        this._dataTable = this._container.find(".dataTable").DataTable({          
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            columnDefs:  [{
                className: 'control',
                orderable: false,
                targets: -1
            },
            {
                responsivePriority: 1,
                targets: [0, 1, -1]
            },
            {
                responsivePriority: 5,
                targets: [2, 3, 4 ]
            }],
            processing: true,
            serverSide: this._serverSide,
            searching: false,
            lengthChange: false,
            info: false,
            paging: this._paging,
            ordering: this._sorting,
            pagingType: "full_numbers",
            lengthMenu: [this._pageSize],
            "fnDrawCallback": function (oSettings) {
                if (!oSettings.json) return;
                const $paginationContainer = $(this).siblings('.dataTables__bottom');

                // hide pagination for single page
                $paginationContainer.toggle(oSettings.json.recordsTotal > inst._pageSize);

                $(window).scrollTop(scrollToTopValue);                
            },
            ajax: {
                method: "GET",
                url: this._dataUrl,
                data: function (data) {
                    data.text = inst._text.val();

                    const sorting = data.order && data.order.length
                        ? data.order[0]
                        : {column: inst._defaultSorting[0], dir: inst._defaultSorting[1]};

                    if (inst._serverSide) {
                        const sortColumn = data.columns[sorting.column];
                        data.sortOrder = (sortColumn.name || sortColumn.data) + "|" + sorting.dir;
                        data.length = data.length > 0 ? data.length : inst._pageSize;
                    }

                    delete data.search;
                    delete data.columns;
                    delete data.order;
                }
            },

            columns: this._columns,
            order: [this._defaultSorting],

            language: {
                paginate: {
                    first: "<i class=\"fal fa-angle-double-left\"></i>",
                    previous: "<i class=\"fal fa-angle-left\"></i>",
                    next: "<i class=\"fal fa-angle-right\"></i>",
                    last: "<i class=\"fal fa-angle-double-right\"></i>"
                },
                processing: $('body > .loading-box').show(),
                loadingRecords: $('body > .loading-box').show(),
                emptyTable: this._language.noRecords
            },

            dom: "rt<\"hide\"i><\"dataTables__bottom\"p>"
        });        

        $.fn.dataTable.ext.errMode = 'none';

        $('.claims-invoices-search--input').on('keypress', function () {
            inst._dataTable.draw();
        });

        this._container.on("click", '.jsSearchButton', function (e) {
            e.preventDefault();
            inst._dataTable.ajax.reload();
        });

        this._container.on("keyup", function (e) {
            if (e.keyCode === 13) {
                inst._dataTable.ajax.reload();
            }
        });

        // Select filter "All" default for desktop
        if ($(window).width() > this.DESKTOP_BREAKPOINT) this._container.find('.jsSearchTrigger').first().prop("checked", true);

        this._container.find('.jsSearchTrigger').on('change', (e) => {
            const value = e.currentTarget.value;
            inst._hidden.val(value);
            inst._dataTable.ajax.reload();
            // remove mobile placeholder on select
            this._container.find('.jsFilterMobilePlaceholder').remove();
            this._container.find('.dropdown__selected').removeClass('d-none d-lg-block');
        });

        $.fn.DataTable.ext.pager.numbers_length = 5;

        if (typeof this._onRowClick === "function") {
            this._container.find(".dataTable").on("click", "tbody tr", function (e) {
                const row = inst._dataTable.row(this);
                const data = row.data();

                inst._onRowClick(e, row, data);
            });
        }
    }
}
window.OrderHistory = OrderHistory;