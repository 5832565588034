class Header {
    constructor() {
        this.Popovers = [];
        this.loadingBox = $('.loading-box');
        this.headerSearch = document.querySelector('.header__search');
    }

    init() {
        var inst = this;
        $('.js-popover').each($.proxy(function (index, value) {
            const referenceId = $(value).attr('id');
            let options = {};

            if (referenceId === 'js-wishlist') {
                options = {
                    offset: {
                        offset: '242px, 2px'
                    }
                };
            } else if (referenceId === 'js-cart') {
                options = {
                    offset: {
                        offset: '162px, 2px'
                    }
                };
            }
        }, this));

        $(document)
            .on('click', '#jsBookmarkToggle', this.bookmark)
            .on('click', '.jsRemoveBookmark', this.removeBookmark)
            .on("change", ".jsSelectCountry", this.renderRegions)
            .on("change", ".jsSelectBillingCountry", this.renderBillingRegions)
            .on("change", ".jsSelectExemptionCountry", this.renderExemptionRegions);
          

        $('.jsUsersSigninBtn').each(function (i, e) {
            $(e).click(function (event) {
                event.preventDefault();
                inst.signin($(this));
            });
        });
        $('.jsUsersSignupBtn').each(function (i, e) {
            $(e).click(function (event) {
                event.preventDefault();
                inst.signup($(this));
            });
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('[data-toggle="dropdown"]').dropdown();

        this.megaMenu();
        this.handleMobileSearch();

        this.checkRememeberMe();
    }

    handleMobileSearch() {
        var inst = this;

        var toggle = document.querySelector('.jsSearchMobile');
        var searchBox = document.querySelector('.header__search .searchbox');
        var searchPopover = document.querySelector('.header__search .searchbox-popover');

        toggle.addEventListener('click', function () {
            inst.headerSearch.classList.toggle('header__search--active');
        });
        // need to discuss functionality.
        //document.addEventListener("click", function (e) {
        //    if (e.target !== toggle && !toggle.contains(e.target) && !searchBox.contains(e.target) && !searchPopover.contains(e.target)) {
        //        inst.headerSearch.classList.remove('header__search--active');
        //    }            
        //});
    }


    checkRememeberMe() { 
        var username = getCookie("username");
        var useremail = getCookie("useremail");
        var rememberMe = getCookie('rememberMe');

        if (rememberMe == 'true') {
            $('.signin__form-header').html(`<h3>Welcome back, ${username}!</h3>`);
            $('input[name="LoginViewModel.Email"]').val(useremail);
            $('input[name="LoginViewModel.RememberMe"]').prop('checked', true);
        }        
    }


    checkEmail(field) {

        var text = $(field).val();

        if (!text || text.length < 4)
        {
            return;
        }

        this.showLoading();

        axios({
            method: 'post',
            url: '/PublicApi/EmailIsAvailable',
            data: {
                email: text
            }
        })
        .then((response) => {
            this.hideLoading();

            if (response.data.success == false) {
                $(field).attr("aria-invalid", true);
                $(field).toggleClass("valid input-validation-error");

                var errorField = $(field).next(".field-validation-valid");
                $(errorField).append(`<span id="RegisterAccountViewModel_Email-error">${response.data.error}</span>`);
                $(errorField).toggleClass("field-validation-valid field-validation-error");
            } else {
                document.dispatchEvent(new CustomEvent('next step'));
            }
        })
        .catch(function (response) {
            console.error("Unable to check email");
        })
    }

    bookmark(e) {
        e.preventDefault();
        if ($('#jsBookmarkToggle').attr('bookmarked') === undefined) {
            axios({
                method: 'post',
                url: "/Bookmarks/Bookmark",
                data: {
                    contentId: $('#jsBookmarkToggle').attr('contentid')
                }
            }).then(function (response) {
                $('#jsBookmarkToggle').attr('bookmarked', true);
                $('#jsBookmarkToggle').html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark" style="fill: black;">
                                                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                                    </svg>`);
            }).catch(function (response) {
                console.log(response);
            });
        } else {
            axios({
                method: 'post',
                url: "/Bookmarks/Unbookmark",
                data: {
                    contentId: $('#jsBookmarkToggle').attr('contentid')
                }
            }).then(function (response) {
                $('#jsBookmarkToggle').removeAttr('bookmarked');
                $('#jsBookmarkToggle').html(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark" style="fill: none;">
                                                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                                    </svg>`);
            }).catch(function (response) {
                console.log(response);
            });
        }
    }

    removeBookmark(e) {
        e.preventDefault();
        var contentGuid = e.currentTarget.attributes["contentguid"].value;
        axios({
            method: 'post',
            url: "/Bookmarks/Remove",
            data: {
                contentGuid: contentGuid
            }
        }).then(function (response) {
            var rowId = '#bookmark-' + contentGuid;
            $(rowId).remove();
        }).catch(function (response) {
            console.log(response);
        });
    }


    megaMenu() {
        $('.navigation__left .navigation__item').each(function (i, e) {
            $(e).mouseenter(function () {
                var dropdown = $(e).find('.mega-container').first();
                var top = $(e)[0].getBoundingClientRect();
                $(dropdown).css('top', top.bottom + 1 + 'px');
                $(dropdown).css('left', '0px');
            })
        });
    }

    signin(e) {
        let form = $(e).closest("form");
        if (!form.valid()) {
          return;
        }

        const email = $("#LoginViewModel_Email", form).val();
        const password = $("#LoginViewModel_Password", form).val();
        const rememberMe = $('input[name="LoginViewModel.RememberMe"]', form).is(':checked');
        const currentUri = new URLSearchParams(location.search);
        const returnUrl = currentUri.get("returnUrl") || currentUri.get("ReturnUrl") || location.href;

        const bodyFormData = new FormData();
        bodyFormData.append('Email', email);
        bodyFormData.append('Password', password);
        bodyFormData.append('RememberMe', rememberMe);
        bodyFormData.append('ReturnUrl', returnUrl);
        bodyFormData.append('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", form).val());
        $('.loading-box').show();
        axios({
            method: 'post',
            url: form[0].action,
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(function (response) {
                if (!response.data.success) {
                    var errorMessages = document.getElementsByClassName('js-login-signin-errormessage');
                    if (errorMessages.length > 0) {
                        for (var i = 0; i < errorMessages.length; i++) {
                            errorMessages[i].innerText = '';
                            errorMessages[i].style.display = "block";
                            for (var error in response.data.errors) {
                                $(errorMessages[i]).append(response.data.errors[error] + '<br />');
                            }
                        }
                    }
                }
                else {
                    if (rememberMe) {
                        setCookie('rememberMe', true, 365);                                                                       
                    } else {
                        deleteCookie('rememberMe');
                    }                   

                    if (response.data.returnUrl) {
                        window.location.href = response.data.returnUrl;
                    } else {
                        window.location.href = "/";
                    }
                    $(".modal-sign-in").hide();
                }
            })
            .catch(function (response) {
                document.getElementsByClassName('js-login-signin-errormessage').innerText = response;
                
            })
            .finally(function () {
                $('.loading-box').hide();
            });
    }

    convertToJsonObject(arrayData) {
        var indexed_array = {};

        $.map(arrayData, function (n, i) {
            indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
    }

    signup(e) {
        let $form = $(e).closest("form");
        let form = $form[0];
        
        if ($form.valid()) {
            let bodyFormData = new FormData();

            bodyFormData.set('Email', $("#RegisterAccountViewModel_Email", form).val());
            bodyFormData.set('Password', $("#RegisterAccountViewModel_Password", form).val());
            bodyFormData.set('Password2', $("#RegisterAccountViewModel_Password2", form).val());
            bodyFormData.set('Address.FullName', $("#RegisterAccountViewModel_Address_FullName", form).val());
            bodyFormData.set('Newsletter', $('#RegisterAccountViewModel_Newsletter', form).is(':checked'));
            bodyFormData.set('IsSalesTaxExempt', $('#RegisterAccountViewModel_IsSalesTaxExempt', form).is(':checked'));
            bodyFormData.set('Address.Organization', $("#RegisterAccountViewModel_Address_Organization", form).val());
            bodyFormData.set('Website', $("#RegisterAccountViewModel_Website", form).val());
            bodyFormData.set('BusinessType', $("#RegisterAccountViewModel_BusinessType", form).val());
            bodyFormData.set('Address.DaytimePhoneNumber', $("#RegisterAccountViewModel_Address_DaytimePhoneNumber", form).val());
            bodyFormData.set('SalesChannel', $(".registration__sales-channel :radio:checked", form).first().val());

            var selectedProducts = $("input[name='RegisterAccountViewModel_TopProducts']:checked");
            var topProducts = "";
            selectedProducts.each(function () {
                topProducts += `${$(this).val()};`;
            })
            bodyFormData.set('TopProducts', topProducts);

            bodyFormData.set('Address.Line1', $("#RegisterAccountViewModel_Address_Line1", form).val());
            bodyFormData.set('Address.Line2', $("#RegisterAccountViewModel_Address_Line2", form).val());
            bodyFormData.set('Address.City', $("#RegisterAccountViewModel_Address_City", form).val());
            bodyFormData.set('Address.PostalCode', $("#RegisterAccountViewModel_Address_PostalCode", form).val());
            bodyFormData.set('Address.CountryCode', $("#RegisterAccountViewModel_Address_CountryCode", form).val());
            bodyFormData.set('Address.CountryName', $("#RegisterAccountViewModel_Address_CountryName", form).text() || '');
            bodyFormData.set('Address.CountryRegion.Region', $(".jsRegion:not(.d-none)", form).val());

            let _billingAddressDifferent = $('#RegisterAccountViewModel_BillingAddressDifferent', form).is(':checked');
            bodyFormData.set('BillingAddressDifferent', _billingAddressDifferent);
            if (_billingAddressDifferent) {
                bodyFormData.set('BillingAddress.FullName', $("#RegisterAccountViewModel_Address_FullName", form).val());
                bodyFormData.set('BillingAddress.DaytimePhoneNumber', $("#RegisterAccountViewModel_Address_DaytimePhoneNumber", form).val());
                bodyFormData.set('BillingAddress.Organization', $("#RegisterAccountViewModel_Address_Organization", form).val());
                bodyFormData.set('BillingAddress.Line1', $("#RegisterAccountViewModel_BillingAddress_Line1", form).val());
                bodyFormData.set('BillingAddress.Line2', $("#RegisterAccountViewModel_BillingAddress_Line2", form).val());
                bodyFormData.set('BillingAddress.City', $("#RegisterAccountViewModel_BillingAddress_City", form).val());
                bodyFormData.set('BillingAddress.PostalCode', $("#RegisterAccountViewModel_BillingAddress_PostalCode", form).val());
                bodyFormData.set('BillingAddress.CountryCode', $("#RegisterAccountViewModel_BillingAddress_CountryCode", form).val());
                bodyFormData.set('BillingAddress.CountryName', $("#RegisterAccountViewModel_BillingAddress_CountryName", form).text() || '');
                bodyFormData.set('BillingAddress.CountryRegion.Region', $(".jsBillingRegion:not(.d-none)", form).val());
            }
            
            bodyFormData.set('TaxExemption.MultipleStates', $(".registration__multiple-states :radio:checked", form).first().val());
            let _exemptionSinglePurchase = $(".registration__single-purchase :radio", form).first().is(':checked');
            bodyFormData.set('TaxExemption.SinglePurchase', _exemptionSinglePurchase);
            if (_exemptionSinglePurchase) {
                bodyFormData.set('TaxExemption.RelatedInvoice', $("#RegisterAccountViewModel_TaxExemption_RelatedInvoice", form).val());
            }
            bodyFormData.set('TaxExemption.ClaimingCountryRegion.Region', $("#RegisterAccountViewModel_TaxExemption_ClaimingCountryRegion_Region", form).val());
            bodyFormData.set('TaxExemption.Reason', $("#RegisterAccountViewModel_TaxExemption_Reason", form).val() || '');
            bodyFormData.set('TaxExemption.Industry', $("#RegisterAccountViewModel_TaxExemption_Industry", form).val() || '');
            bodyFormData.set('TaxExemption.TaxId', $("#RegisterAccountViewModel_TaxExemption_TaxId", form).val());
            bodyFormData.set('TaxExemption.CountryCode', $("#RegisterAccountViewModel_TaxExemption_CountryCode", form).val());
            bodyFormData.set('TaxExemption.CountryName', $("#RegisterAccountViewModel_TaxExemption_CountryName", form).text() || '');
            bodyFormData.set('TaxExemption.CountryRegion.Region', $(".jsExemptionRegion:not(.d-none)", form).val());
            bodyFormData.set('TaxExemption.IsInformationCorrect', $("#RegisterAccountViewModel_TaxExemption_IsInformationCorrect", form).val());
            bodyFormData.set('TaxExemption.ReceiveEmails', $("#RegisterAccountViewModel_TaxExemption_ReceiveEmails", form).val());
            bodyFormData.set('TaxExemption.PrintName', $("#RegisterAccountViewModel_TaxExemption_PrintName", form).val());

            bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", form).val());

            grecaptcha.execute();

            this.showLoading();
            axios({
                method: 'post',
                url: form.action,
                data: bodyFormData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
                .then((response) => {
                    if (response.data.success == false) {
                        var errorMessage = document.getElementById('login-signup-errormessage');
                        if (errorMessage) {
                            errorMessage.innerText = '';
                            errorMessage.style.display = "block";
                            for (var error in response.data.errors) {
                                $('#login-signup-errormessage').append(response.data.errors[error] + '<br />');
                            }
                        }
                    }
                    else {
                      $(".jsSignUpForm").remove();
                      $(".jsSignUpSuccess").removeClass('d-none');
                      $(".jsSignUpSuccessMessage").html(response.data.message);
                    }
                })
                .catch((response) => {
                    var errorPanel = document.getElementById('login-signup-errormessage');
                    errorPanel.innerText = response;
                    errorPanel.style.display = "block";
                })
                .finally(() => {
                    this.hideLoading();
                });
        }
    }

    renderRegions()
    {
        var $countryCode = $(".jsSelectCountry").val();
        axios.post("/publicapi/GetRegionsForCountry/", { countryCode: $countryCode })
            .then(function (response) {
                if (response.data.success == true) {
                    let regions = $(".jsSelectRegion");       
                    let textBox = $(".jsTextRegion");
                    let formattedRegions;

                    // check if first result is an empty string and remove it
                    if (response.data.regions.length > 0 && response.data.regions[0] === "") { 
                        formattedRegions = response.data.regions.slice(1);
                    } else {
                        formattedRegions = response.data.regions;
                    }                    

                    if (formattedRegions.length > 0) {
                        textBox.addClass('d-none');
                        regions.removeClass('d-none');
                        for (let selector of regions) {
                            var regionDropdown = $(selector);
                            if (regionDropdown) {
                                $(regionDropdown).html(""); //cleanup dropdown                                    
                                for (let region in formattedRegions) {
                                    $(regionDropdown).append(`<option value="${formattedRegions[region]}">${formattedRegions[region]}</option>`);
                                }
                            }
                        }
                    } else {
                        regions.addClass('d-none');
                        textBox.removeClass('d-none');
                        $(textBox).html("");
                        $(textBox).val("");
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    renderBillingRegions()
    {
        var $countryCode = $(".jsSelectBillingCountry").val();
        axios.post("/publicapi/GetRegionsForCountry/", { countryCode: $countryCode })
            .then(function (response) {
                if (response.data.success == true) {
                    let regions = $(".jsSelectBillingRegion");       
                    let textBox = $(".jsTextBillingRegion");

                    if (response.data.regions.length > 0) {
                        textBox.addClass('d-none');
                        regions.removeClass('d-none');
                        for (let selector of regions) {
                            var regionDropdown = $(selector);
                            if (regionDropdown) {
                                $(regionDropdown).html(""); //cleanup dropdown                                    
                                for (let region in response.data.regions) {
                                    $(regionDropdown).append(`<option value="${response.data.regions[region]}">${response.data.regions[region]}</option>`);
                                }
                            }
                        }
                    } else {
                        regions.addClass('d-none');
                        textBox.removeClass('d-none');
                        $(textBox).html("");
                        $(textBox).val("");
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    renderExemptionRegions()
    {
        var $countryCode = $(".jsSelectExemptionCountry").val();
        axios.post("/publicapi/GetRegionsForCountry/", { countryCode: $countryCode })
            .then(function (response) {
                if (response.data.success == true) {
                    let regions = $(".jsSelectExemptionRegion");       
                    let textBox = $(".jsTextExemptionRegion");

                    if (response.data.regions.length > 0) {
                        textBox.addClass('d-none');
                        regions.removeClass('d-none');
                        for (let selector of regions) {
                            var regionDropdown = $(selector);
                            if (regionDropdown) {
                                $(regionDropdown).html(""); //cleanup dropdown                                    
                                for (let region in response.data.regions) {
                                    $(regionDropdown).append(`<option value="${response.data.regions[region]}">${response.data.regions[region]}</option>`);
                                }
                            }
                        }
                    } else {
                        regions.addClass('d-none');
                        textBox.removeClass('d-none');
                        $(textBox).html("");
                        $(textBox).val("");
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    setRegion() {
        var $countryCode = $(this).val();
        var $addressRegionContainer = $(".address-region");
        var $region = $(".address-region-input", $addressRegionContainer).val();
        var $htmlPrefix = $("input[name='address-htmlfieldprefix']", $(this).parent()).val();
        var $url = "/AddressBook/GetRegionsForCountry/";
        axios.post($url, { countryCode: $countryCode, region: $region, htmlPrefix: $htmlPrefix })
            .then(function (response) {
                $addressRegionContainer.replaceWith($(result));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    showLoading() {
        this.loadingBox.show();
    }

    hideLoading() {
        this.loadingBox.hide();
    }
}
