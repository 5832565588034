window.onYouTubeIframeAPIReady = function() {

    $('.jsOneUpVideoModal').each(function () {

        var id = $(this).data('youtube-id');
        var divId = $(this).data('target');
       
        var player = new YT.Player(divId, {
            height: '100%',
            width: '100%',
            videoId: id
        });    

        $(this).on('shown.bs.modal', function (e) {
            if (player != undefined) {
                player.playVideo();
            }
        });
        $(this).on('hidden.bs.modal', function (e) {
            if (player != undefined) {
                player.stopVideo();
            }
        });

    });
}
