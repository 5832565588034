class Favorites {
    constructor() {      
        this.init();        
    }

    init() {    
        let inst = this;
        $('.jsAddSkus').on('click', this.addSkusList.bind(this));
        $('#showPrices').on('change', this.togglePriceView.bind(this));    
        $('#addSkusTextarea').on("change", function () {
            $('#addSkusErrorMessage, #addSkusSuccessMessage').addClass('d-none');            
        });
        
    }

    addSkusList(e) {        
        e.preventDefault();

        let inst = this;

        let skusList = [];

        let productList = [];

        let textareaValue = $('#addSkusTextarea').val();

        if (textareaValue != '') {

            skusList = textareaValue;

            if (skusList.length > 0) {

                let form = $(e.target).closest("form");

                $('.loading-box').show();

                let bodyFormData = new FormData();
                bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", form).val());
                
                bodyFormData.append('codes', skusList);

                axios({
                    method: 'post',
                    url: form[0].action,
                    data: bodyFormData,
                    config: {headers: {'Content-Type': 'multipart/form-data'}}
                }).then(function (response) {
                    if (response.data.StatusCode == 0) {                    
                        inst.showErrorMessage(response.data.Message);
                    }
                    else {
                        inst.showSuccessMessage(response.data.Message);

                        $(response.data.FavoritesWishListAdded).each(function (i, e) {
                            productList.push(inst.BuildGa4Object(e));
                        });

                        if (Object.keys(productList).length) {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: 'add_to_wishlist',
                                ecommerce: {
                                    items: productList
                                }
                            });
                        }

                        productList = [];

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }                   
                }).catch(function (response) {
                    inst.showErrorMessage(response);
                }).finally(function () {
                    $('.loading-box').hide();
                });
            }

        }
    }

    togglePriceView(e) {     

        const $toggle = $(e.target);
        const $link = $("#downloadFavoritesLink");
                
        let url = $link.data('url');
        let sortOrder = $link.data('sort');
        let hidePrice = !$toggle.prop('checked');
    
        let link = `${url}?_pdf=1&sort=${sortOrder}&hidePrice=${hidePrice}`;
        $link.attr('href', link);

        $('#favoritesList').toggleClass('favorites__content--noprice');         
    }

    showErrorMessage(message) {
        $('#addSkusErrorMessage').text(message).removeClass('d-none');
    }

    showSuccessMessage(message) {
        $('#addSkusSuccessMessage').text(message).removeClass('d-none');
    }

    BuildGa4Object(object) {
        return {
            item_name: object.ItemName || '1',
            item_id: object.ItemId || '',
            price: object.Price || '',
            item_brand: object.ItemBrand || '',
            item_category: object.ItemCategory || '',
            item_category2: object.ItemCategory2 || '',
            item_category3: object.ItemCategory3 || '',
            item_category4: object.ItemCategory4 || '',
            item_category5: object.ItemCategory5 || '',
            item_variant: object.ItemVariant || '',
            item_list_name: object.ItemListName || '',
            item_list_id: object.ItemListId || '',
            index: object.Index || '',
            quantity: object.Quantity || ''
        }
    }
}