class AddressBook {

    Init() {
        this.CountryClick();
        this.EditAddressClick();
        this.SaveAddress();

    }

    CountryClick(selector) {
        if (selector == undefined || selector == "") {
            selector = ".jsCountrySelectionContainer";
        }

        $(selector).unbind('change').change(function () {
            var countryCode = $(this).find('option:selected').val();
            var region = $(this).find('option:selected').val();
            var inputName = $(this).closest('form').find('.jsRegionName').val();
            var element = $(this);
            axios.get("/addressbook/GetRegions?countryCode=" + countryCode + "&region=" + region + "&inputName=" + inputName)
                .then(function (r) {
                    if ($(element).parents('form').length > 0) {
                        var region = $(element).closest('form').find('.jsCountryRegionContainer').first();
                        region.html(r.data);
                    } else {
                        var region = $(element).parent().siblings('.jsCountryRegionContainer').first();
                        region.html(r.data);
                    }

                    feather.replace();
                    var dropdown = new Dropdown(region);
                    dropdown.Init();
                })
                .catch(function (e) {
                    notification.Error(e);
                })
        })
    }    


    SaveAddress() {
        var inst = this;

        $('.jsSaveAddressBookForm').each(function (i, e) {

            var $form = $(e).closest("form");
            $form.validate();
            var form = $form[0];

            $(e).click(function (event) {
                event.preventDefault();               

                if ($form.valid()) {
                    
                    //cleanup errors
                    $(form).find('.jsAddressError').html("");
                    $(form).find('.jsAddressError').removeClass('error');

                    var bodyFormData = inst.getAddressFormData(form, "Address");
                    $('.loading-box').show();
                  
                    axios({
                        method: 'post',
                        url: form.action,
                        data: bodyFormData,
                        config: { headers: { 'Content-Type': 'multipart/form-data' } }
                    })
                        .then(function (response) {
                            if (response.data.Success === false && response.data.Address) {
                                // TODO: show modal
                                console.log('---Corrected address from FedEx---')
                                console.log(response.data.Address);
                            } else if (response.data.Success === false) {
                                $(form).find('.jsAddressError').html(response.data.Message);
                                $(form).find('.jsAddressError').addClass('error');
                            } else {
                                $('address-book__modal').hide();
                                window.location.href = response.data.ReturnUrl;
                            }
                        })
                        .catch(function (error) {
                            notification.Error(error);
                            $(form).find('.jsAddressError').html(error);
                            $(form).find('.jsAddressError').addClass('error');

                        })
                        .finally(function () {
                            $('.loading-box').hide();
                        });
                }   
            });
        });
    }

    EditAddressClick(selector = ".jsEditAddressButton") {

        this.modal = $('#editAddressModal');

        var inst = this;

        var modalContent = $("#AddressModalContent");

        $(selector).unbind( "click" ).on('click', function () {             

            var verificationToken = $(this).siblings('input[name="__RequestVerificationToken"]').val();

            var url = $(this).data('url');
            const data = new FormData();           
           
            data.append("__RequestVerificationToken", verificationToken);
            
            axios.get(url, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest"
                    }
                })
                .then(function (r) {
                    
                    modalContent.html(r.data);
                    feather.replace();           
                    var addressBook = new AddressBook();
                    addressBook.Init();
                    inst.modal.modal('show');
                    
                })
                .catch(function (e) {
                notification.Error(e);
            });
        });
    }

    RemoveAddressClick(selector = ".jsRemoveAddressButton") {

        $(selector).unbind('click').on('click', function () {                     

            var url = $(this).data('url'); 
            
            var modalRemoveBtn = $('.jsRemoveAddressConfirmed');

            modalRemoveBtn.one("click", function () {
                axios.get(url, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest"
                    }
                })
                    .then(function (r) {                                          
                    location.reload();
                })
                .catch(function (e) {
                notification.Error(e);
            });
            });           
            
        });
    }

    ////// helpers
    getAddressFormData(form, formName) {

        var bodyFormData = new FormData();
        bodyFormData.set('AddressId', $(`#${formName}_AddressId`, form).val());
        bodyFormData.set('FullName', $(`#${formName}_FullName`, form).val());
        bodyFormData.set('DaytimePhoneNumber', $(`#${formName}_DaytimePhoneNumber`, form).val());
        bodyFormData.set('Organization', $(`#${formName}_Organization`, form).val());
        bodyFormData.set('CountryCode', $(`select[name="${formName}.CountryCode"]`, form).val());
        bodyFormData.set('Line1', $(`#${formName}_Line1`, form).val());
        bodyFormData.set('Line2', $(`#${formName}_Line2`, form).val());
        bodyFormData.set('City', $(`#${formName}_City`, form).val());
        bodyFormData.set('CountryRegion.Region', $('.js-region:visible', form).val());
        bodyFormData.set('PostalCode', $(`#${formName}_PostalCode`, form).val());
        bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]").val());
        bodyFormData.set('ShippingDefault', $(`#${formName}_ShippingDefault`, form).prop("checked"));
        return bodyFormData;
    }
    //////
}