var quantityHandler = function () {
    $('body').on('click', '.controls__button', function () {
        let $button = $(this),
            input = $button.parent().find('input'),
            oldValue = input.val(),
            newValue,
            max = parseInt(input.attr('max')),
            min = parseInt(input.attr('min')),
            step = parseInt(input.attr('step')), 
            addToCart = $button.closest('.product-thumbnail__quantity, .product-detail__buy, .quick-view--modal__buy, .product-thumbnail_product-form').find('.addToCart');

        if ($button.hasClass('qty-minus') && parseInt(oldValue) <= parseInt(min)) {
            return;
        }

        if ($button.hasClass('qty-plus')) {
            newValue = parseInt(oldValue) + parseInt(step);
        }
        else if (oldValue > parseInt(min)) {
            newValue = parseInt(oldValue) - parseInt(step);
        }
        else {
            newValue = parseInt(min);
        }

        if (newValue <= parseInt(max)) {
            input.val(newValue);
            addToCart.attr('qty', newValue);
        }
        input.trigger('change');
    });

    $('body').on('focusout', '.controls__field', function () {
        let $input = $(this),
            newValue = $input.val(),
            addToCart = $input.closest('.product-thumbnail__quantity, .product-detail__buy, .quick-view--modal__buy, .product-thumbnail_product-form').find('.addToCart');                
        let valueInt = parseInt(newValue);
        let minValue = parseInt($input.attr('min'));

        if (valueInt <= minValue) {
            $input.val(minValue);
            addToCart.attr('qty', minValue);

        } else {
            let step = parseInt($input.attr('step'));
            let higherBound = Math.ceil(valueInt / step) * step;
            let lowerBound = Math.floor(valueInt / step) * step;
            valueInt = Math.abs(valueInt - higherBound) < Math.abs(valueInt - lowerBound) ? higherBound : lowerBound;
            $input.val(valueInt);

            addToCart.attr('qty', valueInt);
        }
    });
};

quantityHandler();
