class StoreLocator {

    GetStores() {
        const inst = this;        
        $('.jsGetStores').each(function (i, e) {
            $(e).click(function (event) {
                event.preventDefault();
                
                let $form = $(e).closest("form");
                let form = $form[0];

                if ($form.valid()) {
                    let bodyFormData = new FormData();
                    bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]").val());
                    bodyFormData.set('ZipCode', $('#ZipCode', form).val());
                    bodyFormData.set('Radius', $('#Radius', form).val());
                    $('.loading-box').show();
                    axios({
                        method: 'post',
                        url: form.action,
                        data: bodyFormData,
                        config: { headers: { 'Content-Type': 'multipart/form-data' } }
                    })
                        .then(function (response) {
                            if (response.data.Success === true && response.data.Count !== 0) {
                                $('.jsStores').html(response.data.Stores);
                                $('.search-error').addClass('hidden');                                                               
                            } else {
                                $('.search-error').removeClass('hidden');
                                $('.store').addClass('hidden');
                            }
                            $form.find("#ZipCode").blur();
                            $('.jsStoreCountWrapper').show();
                            $('.jsStoreCount').html(response.data.Count);
                            $('.jsZipCode').html(response.data.ZipCode);
                        })
                        .catch(function (error) {
                            notification.Error(error);

                        })
                        .finally(function () {
                            inst.GenerateMap();
                            $('.loading-box').hide();
                            
                        });
                }
            });
        });
    }

    GenerateMap() {
        const inst = this;
        var map;
        let locations = [];
        let dataLat = $('.store').attr('data-lat');
        let dataLng = $('.store').attr('data-long');
        let stores = document.querySelectorAll('.store');
        stores && stores.forEach(function (store) {
            let markerInfo = [];
            let storeInfo = $(store).find('.store__details').html(); 
            markerInfo.push(storeInfo);
            markerInfo.push(store.dataset.lat);
            markerInfo.push(store.dataset.long);
            locations.push(markerInfo);
        });    
        if (dataLat != null || dataLng != null) {
            map = new google.maps.Map(document.getElementById("map"), {
                center: new google.maps.LatLng(dataLat, dataLng),
                zoom: 6,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });  
        }
        else {
            map = new google.maps.Map(document.getElementById("map"), {
                center: new google.maps.LatLng(31.968, -99.901),
                zoom: 5,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });  
        }
               
        var infowindow = new google.maps.InfoWindow({})
        var marker, i
        let markers = [];
        for (i = 0; i < locations.length; i++) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                
            })
            google.maps.event.addListener(
                marker,
                'click',
                (function (marker, i) {
                    return function () {
                        infowindow.setContent(locations[i][0])
                        infowindow.open(map, marker)
                        let activeStore = $('.store')[i]
                        $('.store').removeClass('active');
                        $('.store').find('.icon').removeClass('active');
                        $(activeStore).addClass('active');
                        $(activeStore).find('.icon').addClass('active');
                    }
                })(marker, i)
            );
            markers.push(marker);           
        }
        $(document).on('click', '.store__name--icon', function (e) {           
            let id = $(this).attr('data-num');
            google.maps.event.trigger(markers[id], 'click');
        });
    }      
}