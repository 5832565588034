class AccountActivate {
    constructor() {
        this.init();
    }

    init() {

        let activateAccountPage = $(".activate-account-page");
        this.checkboxErrorMessage = $(".field-validation-error.top-products");
        this.loadingBox = $('.loading-box');

        if (activateAccountPage.length > 0) {

            this.form = activateAccountPage.find('form')[0];

            activateAccountPage.find('form').validate();

            $('#Password2').rules("add", {
                equalTo: "#Password",
            });

            this.activateByEmail();
            this.activateByNumber();
            this.activateAccount();
        }
    }

    getFormParams() {
        let inputs = this.form.elements;
        let paramsString = '';
        for (let i = 0; i < inputs.length; i++) {
            paramsString = paramsString + '&' + inputs[i].name + '=' + inputs[i].value;
        }

        return paramsString;
    }

    showErrors(data) {

        let errorsPlace = $('.activate-account__errors');

        errorsPlace.empty();

        data.forEach(message => {
            errorsPlace.append($('<div class="alert alert-danger alert-dismissible fade show" role="alert">' + message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>'))
        });
    }

    showSuccessMessage(message) {
        let content = $('.activate-account__content');
        let successContainer = $(".activate-account__success");
        let messagePlaceholder = $(".activate-account__success-message");
        content.empty();
        messagePlaceholder.replaceWith($(message));
        successContainer.removeClass('d-none');

        scrollToElem("header");
    }

    activateByEmail() {

        var proxy = this;

        this.btnActivateByEmail = $('.jsBtnActivateByEmail');

        this.btnActivateByEmail.on('click', (event) => {

            if (!$(proxy.form).valid()) {
                return;
            }

            event.preventDefault();
            this.showLoading();

            axios.post(proxy.form.action, proxy.getFormParams())

                .then((response) => {
                    if (response.data.success) {
                        proxy.showSuccessMessage(response.data.message);
                    } else {
                        let validator = $(proxy.form).validate();
                        validator.showErrors({ "Email": response.data.errors });
                    }
                }
                )
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.hideLoading();
                });
        });
    }

    activateByNumber() {

        var proxy = this;

        this.btnActivateByNumber = $('.jsBtnActivateByNumber');

        this.btnActivateByNumber.on('click', (event) => {

            if (!$(proxy.form).valid()) {
                return;
            }

            event.preventDefault();
            this.showLoading();

            axios.post(proxy.form.action, proxy.getFormParams())

                .then(function (response) {
                    if (response.data.success) {
                        window.location.href = response.data.redirectUrl;
                    } else {
                        let validator = $(proxy.form).validate();
                        validator.showErrors({ "Number": response.data.errors });
                    }
                }
                )
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.hideLoading();
                });
        });
    }

    activateAccount() {
        var proxy = this;

        this.btnActivateAccount = $('.jsBtnActivateAccount');

        // reenter phone number
        if ($('#PhoneNumber').length > 0) {
            $('#PhoneNumber').val($('#PhoneNumber').val().replaceAll('-', ''));
        }

        this.btnActivateAccount.on('click', (event) => {
            event.preventDefault();

            let bodyFormData = new FormData();
            bodyFormData.set('Name', $("#Name", $(proxy.form)).val());
            bodyFormData.set('Email', $("#Email", $(proxy.form)).val());
            bodyFormData.set('Password', $("#Password", $(proxy.form)).val());
            bodyFormData.set('Password2', $("#Password2", $(proxy.form)).val());
            bodyFormData.set('CompanyName', $("#CompanyName", $(proxy.form)).val());
            bodyFormData.set('Website', $("#Website", $(proxy.form)).val());
            bodyFormData.set('PhoneNumber', $("#PhoneNumber", $(proxy.form)).val());
            bodyFormData.set('SalesChannel', $("input[name='SalesChannel']:checked", $(proxy.form)).first().val());


            let selectedProducts = $("input[name='TopProducts']:checked");
            let topProducts = "";
            selectedProducts.each(function () {
                topProducts += `${$(this).val()};`;
            })

            bodyFormData.set('TopProducts', topProducts);

            bodyFormData.set('AccountNumber', $("#AccountNumber", $(proxy.form)).val());
            bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", $(proxy.form)).val());

            let $validator = $(proxy.form).validate();
            $validator.form();
            if (!proxy.validateCheckboxes(proxy.form)) {
                proxy.checkboxErrorMessage.show();
                $validator.form();
            } else {
                proxy.checkboxErrorMessage.hide();
                if ($(proxy.form).valid()) {
                    proxy.submitFormData(bodyFormData);
                } else {
                    $validator.form();
                }
            }

        });
    }

    submitFormData(data) {
        let _this = this;
        this.showLoading();
        axios({
            method: 'post',
            url: _this.form.action,
            data: data,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(function (response) {
                //check if JSON returned 
                if (response.data.success != undefined) {
                    if (response.data.success) {
                        window.location.href = response.data.redirectUrl;
                    } else {
                        _this.showErrors(response.data.errors);
                    }
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                this.hideLoading();
            });
    }

    validateCheckboxes(container) {
        let numChecks = $(container).find('input[name="TopProducts"]:checked').length;
        return (numChecks > 0 && numChecks < 4);
    }

    showLoading() {
        this.loadingBox.show();
    }

    hideLoading() {
        this.loadingBox.hide();
    }
}

