class OrderConfirmation {
    constructor() {
    }

    InitJsGaPurchase() {
        var inst = this;
        if (window.location.href.indexOf("order-confirmation") > 0) {
            const productList = [];
            const purchase = document.querySelector('.GA4_purchase');

            if(purchase) {
                const itens =  document.querySelectorAll('.jsGa4-order_confirmation_item');

                itens.forEach(item => {
                    let product = inst.BuildGa4ObjectPurchase(item.dataset);
                    productList.push(product);
                });

                if (productList) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'purchase',
                        currency: purchase.dataset.itemCurrency,
                        value: parseFloat(purchase.dataset.itemValue || 0).toFixed(2),
                        tax: parseFloat(purchase.dataset.itemTax || 0).toFixed(2),
                        shipping: parseFloat(purchase.dataset.itemShipping || 0).toFixed(2),
                        affiliation: purchase.dataset.itemAffiliation,
                        transaction_id: purchase.dataset.itemTransactionId,
                        coupon: purchase.dataset.itemCoupon,
                        ecommerce: {
                            items: productList
                        }
                    });

                    productList = [];
                }
            }
        }
    }

    BuildGa4ObjectPurchase(dataset) {
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: parseFloat(dataset.price || 0).toFixed(2),
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            quantity: dataset.item_quantity || ''
        }
    }
}