class ForgotPassword {
    constructor() {
        this.$el = $('.forgot-password-page');
        this.form = this.$el.find('form');
        this.submitButton = this.$el.find('input[type="submit"]');
        this.emailInputField = this.$el.find("#Email");
        this.init();
    }
    
    handleClick(e) {
        if (this.form.valid()) {
            this.checkEmail(this.emailInputField);
        }
    }

    checkEmail(field) {
        axios({
            method: 'post',
            url: '/PublicApi/IsUserRegistered',
            data: {
                email: $(field).val()
            }
        })
            .then(response => {
                if (response.data.success == false) {
                    let validator = $(this.form).validate();
                    validator.showErrors({ "Email": response.data.message });

                    
                } else {
                    this.form.submit();
                }
            })
            .catch(function () {
                console.error("Unable to check email");
            })
    }

    init() {
        this.submitButton.on('click', e => {
            e.preventDefault();
            this.handleClick(e);
        })
    }

}

