class MyProfile {

    constructor() {
        this.init();
        this.changedForm = false;
    }

    init() {
        var inst = this;

        this.profileArea = $('#editProfileArea');
        this.changePasswordArea = $('#changePasswordArea');
        this.confirmModal = $('#editProfileChangedModal');

        if (this.profileArea.length > 0 && this.changePasswordArea.length > 0) {

            $("#ChangePassword").validate();

            this.profileArea.find("form").validate({
                errorElement: "span",
                errorClass: "validation-error",
                errorPlacement: function (error, element) {
                    error.appendTo(element.parent().parent().find('.validation-message'));
                },
                rules: {
                    ShippingCarriersOption: {
                        required: true,                                                 
                    },  
                    ShipVia: {
                       required: "#ShippingCarriersOption_1:checked"
                    },
                    ShipViaAccount: {
                        required: "#ShippingCarriersOption_1:checked"
                    }                             
                },               
            });

            this.checkboxErrorMessage = $(".field-validation-error.top-products");

            $('#ChangePassword_Password2').rules("add", {
                equalTo: "#ChangePassword_Password",
            });

            // toggle password change tab
            $(".jsEditProfileCancelBtn, .jsChangePasswordShow").click(function (e) {
                e.preventDefault();               
                inst.checkIfChangesSaved(e, inst.toggleTabs.bind(inst));                   
            });

            // profile save event
            $('.jsSaveProfile').on('click', function (e) {
                e.preventDefault();
                inst.saveProfile(e);
            });

            // change password save event
            $(".jsBtnChangePassword").on('click', function (e) {
                e.preventDefault();
                inst.saveNewPassword(e);
            });

            // check if changed info not saved
            this.profileArea.find('input, select').on('change', function () {
                inst.changedForm = true;
            });

            $(document).on('click', 'a[href]', function (e) {
                e.preventDefault();
                inst.checkIfChangesSaved(e);
            })

            this.profileArea.on('change', 'input[name="TopProducts"]', function () {
                if (inst.profileArea.find('input[name="TopProducts"]:checked').length != 3) {
                    inst.checkboxErrorMessage.show();                    
                    scrollToTop($('.field-validation-error:visible').eq(0).offset().top - 100);
                } else {
                    inst.checkboxErrorMessage.hide();
                }                
            });
        }
    }

    proceedAction(e, callback) {
        console.log(e);
        if (typeof callback == 'function') {
            callback();
        } else {
            console.log(e.target.href);
            if (e.target.hasAttribute("href")) {
                window.location.href = e.target.href;
            } else {
                var href = e.target.href ? e.target.href : e.currentTarget.URL;
                window.location.href = e.currentTarget.URL;
            }
        }
    }

    checkIfChangesSaved(e, callback) {

        let inst = this;           
        
        if (this.changedForm) {          
            inst.confirmModal.modal('show');
            inst.confirmModal.find('.jsLeavePage').on('click', function () {  
                debugger;
                inst.changedForm = false;                
                inst.proceedAction(e, callback);                               
            });
        } else {
            this.proceedAction(e, callback);    
        }
    }

    toggleTabs() {
        if (this.profileArea.hasClass('d-none')) {
            this.clearPasswordsFields();
        }
        this.changePasswordArea.toggleClass('d-none');
        this.profileArea.toggleClass('d-none');
    }

    validateCheckboxes(container) {
        return $(container).find('input[name="TopProducts"]:checked').length == 3;
    }

    saveProfile(e) {
        var inst = this;

        let form = $(e.target).closest("form");

        if (form.valid()) {

            if (!inst.validateCheckboxes(form)) {
                inst.checkboxErrorMessage.show();
                return;
            } else {
                inst.checkboxErrorMessage.hide();

                let bodyFormData = new FormData();
                bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", form).val());

                bodyFormData.set('Email', $("#Email", form).val());
                bodyFormData.set('PhoneNumber', $("#PhoneNumber", form).val());
                bodyFormData.set('BusinessType', $("#BusinessType", form).val());
                bodyFormData.set('SalesChannel', $('input[name="SalesChannel"]:checked', form).val());

                var selectedProducts = $("input[name='TopProducts']:checked");
                var topProducts = [];

                selectedProducts.each(function () {
                    topProducts.push($(this).val());
                });

                bodyFormData.set('TopProducts', topProducts);
                bodyFormData.set('ShipVia', $('#ShipVia', form).val());
                bodyFormData.set('ShippingCarriersOption', $('input[name="ShippingCarriersOption"]:checked', form).val());
                bodyFormData.set('ShipViaAccount', $('#ShipViaAccount').val());

                $('.loading-box').show();

                axios({
                    method: 'post',
                    url: form[0].action,
                    data: bodyFormData,
                    config: {headers: {'Content-Type': 'multipart/form-data'}}
                }).then(function (response) {
                    if (response.data.success == false) {

                        if (response.data.errors.length > 0) {
                            for (var error in response.data.errors) {
                                notification.Error(response.data.errors[error]);
                            }
                        } else {
                            notification.Success("Settings saved successfully");
                            window.location.reload();
                        }
                    }
                    else {
                        notification.Success("Settings saved successfully");
                        setTimeout(function () {window.location.reload()}, 1000);
                    }
                }).catch(function (response) {
                    notification.Error(response);

                }).finally(function () {
                    inst.changedForm = false;
                    $('.loading-box').hide();
                });
            }
        } else {            
            scrollToTop($('.validation-error:visible').eq(0).offset().top - 100);
        }
    }

    saveNewPassword(e) {
        var inst = this;

        let form = $(e.target).closest("form");

        if (!form.valid()) {
            return;
        }

        let bodyFormData = new FormData();
        bodyFormData.set('__RequestVerificationToken', $("input[name=__RequestVerificationToken]", form).val());

        bodyFormData.set('CurrentPassword', $("#ChangePassword_CurrentPassword", form).val());
        bodyFormData.set('Password', $("#ChangePassword_Password", form).val());
        bodyFormData.set('Password2', $("#ChangePassword_Password2", form).val());

        $('.loading-box').show();

        axios({
            method: 'post',
            url: form[0].action,
            data: bodyFormData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        }).then(function (response) {
            if (response.data.success == false) {

                for (let error in response.data.errors) {
                    notification.Error(response.data.errors[error]);                
                }

                for (let error in response.data.fieldsErrors) {
                    var errorMessage = $(`<span class="field-validation-error">${response.data.fieldsErrors[error]}</span>`);
                    $('#ChangePassword_' + error).one('change', function () {
                        errorMessage.remove();
                    });
                    $('#ChangePassword_' + error).parents('.form-group').find('.validation-message').append(errorMessage);                    
                }
            }
            else {
                notification.Success("New password saved successfully");
                inst.toggleTabs();
            }
        }).catch(function (response) {
            

            notification.Error(response);
        }).finally(function () {
            $('.loading-box').hide();
        });
    }

    clearPasswordsFields() {
        $("#ChangePassword_CurrentPassword").val('');
        $("#ChangePassword_Password").val('');
        $("#ChangePassword_Password2").val('');
    }
}